<template>
  <div class="page-wrapper">
    
    <header class="main-header always-sticky">
      <div class="left">
        <img src="@/assets/img/logo.svg" class="logo" alt="Logo Tremblant">
        <img src="@/assets/img/tagline-fr.svg" v-if="$route.meta.lang === 'fr'" class="tagline" alt="Tagline Tremblant">
        <img src="@/assets/img/tagline-en.svg" v-else-if="$route.meta.lang === 'en'" class="tagline" alt="Tagline Tremblant">
      </div>
      <div class="right">
        <div class="links">
          <a href="tel:18887381777">1-888-738-1777</a>
          <p>|</p>
          <router-link v-if="pageData && $route && $route.meta.lang === 'fr'" :to="{ name: 'pageEn', params: { pageData } }" class="lang">EN</router-link>
          <router-link v-else-if="pageData && $route && $route.meta.lang === 'en'" :to="{ name: 'page', params: { pageData } }" class="lang">FR</router-link>
        </div>
        <a href="#" class="cta red" @click.prevent="showlodgingform">{{ $t('site.reservez-long') }}</a>
      </div>
      <a href="tel:18887381777" class="phone-mobile"><img src="@/assets/img/phone4.svg" alt="Call Tremblant"></a>
      <div class="mobile-cta">
        <a href="#" class="cta red" @click.prevent="showlodgingform">{{ $t('site.reservez-long') }}</a>
      </div>
    </header>

    <section class="terms-wrap">

      <div v-if="$route.meta.lang === 'fr'">

        <button @click.prevent="goback" class="cta prune">Retour</button><br><br>

        <h1 class="big-title">Modalités<br>Brind'O</h1>

        <h2 class="small-title">Validité</h2>
        <ul>
          <li class="regular-text">Accès à date spécifique.</li>
        </ul>
        
        <h2 class="small-title">Achat et réservation</h2>
        <ul>
          <li class="regular-text">Réservation recommandée en tout temps.</li>
          <li class="regular-text">Peut être acheté d’avance en ligne ou par téléphone au 1-888-738-1777, ou sur place au comptoir Brind’O Aquaclub sous réserve de disponibilité.</li>
          <li class="regular-text">Le Combo jeux aquatiques et salle d’entraînement est disponible au comptoir Brind’O Aquaclub seulement.</li>
          <li class="regular-text">Des tarifs préférentiels sont offerts aux citoyens, Montagnards et employés du Centre de villégiature sur présentation de preuve de statut.</li>
          <li class="regular-text">Pour une réservation de groupes (20 personnes et plus), veuillez composer le 1-866-220-9022.</li>
        </ul>

        <h2 class="small-title">Remboursement</h2>
        <ul>
          <li class="regular-text">Remboursable jusqu’à 72 heures avant le début de votre activité, vous pouvez faire rembourser en totalité sur une carte de crédit. Non remboursable après ce délai.</li>
        </ul>

        <h2 class="small-title">Livraison</h2>
        <ul>
          <li class="regular-text">Vous recevrez un courriel contenant vos billets électroniques 24 heures avant la date prévue de votre activité. Veuillez-vous présenter directement au comptoir brind’O Aquaclub avec ces billets.</li>
        </ul>

        <h2 class="small-title">Restrictions</h2>
        <ul>
          <li class="regular-text">Accès incessible (ne peut être transféré d&#39;une personne à une autre).</li>
        </ul>

        <h2 class="small-title">Règlements</h2>
        <ul>
          <li class="regular-text">Accès aux glissades d’eau: les enfants de 11 ans et moins doivent être accompagnés d’un adulte de 18 ans et plus.</li>
          <li class="regular-text">Accès à la salle d’entraînement et/ou combo: 16 ans et plus (avec présentation de la carte d’identité)</li>
          <li class="regular-text">Port du maillot de bain obligatoire.</li>
          <li class="regular-text">Couche d’eau obligatoire pour les enfants de 2 ans et moins. Seules les couches aquatiques de type Little Swimmers sont acceptées.</li>
          <li class="regular-text">Il est obligatoire de prendre une douche avant d’accéder aux jeux d’eau.</li>
          <li class="regular-text">Port de souliers et de bottes interdit.</li>
          <li class="regular-text">Souliers aquatiques interdits dans les bassins et glissades d'eau.</li>
          <li class="regular-text">Sacs et accessoires interdits.</li>
          <li class="regular-text">Interdiction de courir ou de bousculer.</li>
          <li class="regular-text">Interdiction d’essayer de grimper sur la structure.</li>
          <li class="regular-text">L’alcool et les substances psychotropes sont interdites.</li>
          <li class="regular-text">Interdiction de fumer ou de vapoter sur le site.</li>
          <li class="regular-text">Respecter en tout temps les consignes des préposés en poste.</li>
          <li class="regular-text">Nous ne sommes pas responsables des objets perdus ou volés.</li>
        </ul>

        <!-- <div class="best-price">
          <img src="@/assets/img/best-price-fr.svg" alt="Meilleur prix garanti Tremblant">
          <p class="regular-text">Si vous trouvez sur Internet une réservation en hébergement à un prix plus avantageux que celui que vous avez réservé auprès de notre service, nous honorerons ce prix dans les 48 heures suivant votre réservation. Vous pouvez donc réserver en toute confiance dès maintenant auprès de notre centrale de réservations ou directement à même notre système de réservation en ligne avec l'assurance de toujours obtenir la garantie du meilleur tarif disponible.</p>
        </div> -->
      </div>

      <div v-else-if="$route.meta.lang === 'en'">

        <button @click.prevent="goback" class="cta prune">Back</button><br><br>

        <h1 class="big-title">Brind'O<br>Terms & Conditions</h1>

        <h2 class="small-title">Validity</h2>
        <ul>
          <li class="regular-text">Tickets are date specific</li>
        </ul>
        
        <h2 class="small-title">Purchase and Booking</h2>
        <ul>
          <li class="regular-text">Reservations recommended at all times.</li>
          <li class="regular-text">Can be purchased in advance online or by phone at 1-888-738-1777, or on site at the brind’O Aquaclub counter according to availability.</li>
          <li class="regular-text">The Combo waterplay area and fitness room is available on site at the brind’O Aquaclub counter only.</li>
          <li class="regular-text">Preferential rates are available for citizens, Montagnards and resort employees, upon presentation of proof of status.</li>
          <li class="regular-text">For a group reservation (20 people or more), contact us at 1-866-220-9022.</li>
        </ul>

        <h2 class="small-title">Refund</h2>
        <ul>
          <li class="regular-text">Can be refunded up to 72 hours prior to your activity, your tickets can be refunded on a credit card. Not refundable past this period.</li>
        </ul>

        <h2 class="small-title">Delivery</h2>
        <ul>
          <li class="regular-text">Your tickets will be sent to you by email 24 hours prior to day of the activity. Please present your electronic tickets directly at the brind’O Aquaclub counter.</li>
        </ul>

        <h2 class="small-title">Restrictions</h2>
        <ul>
          <li class="regular-text">Access is non-transferable (cannot be transferred from one person to another).</li>
        </ul>

        <h2 class="small-title">Rules</h2>
        <ul>
          <li class="regular-text">To access the water slides, children aged 11 and under must be accompanied by an adult aged 18 and over.</li>
          <li class="regular-text">Access to the fitness room is reserved for people 16 years and over (with proof of ID).</li>
          <li class="regular-text">Wearing a bathing suit is mandatory.</li>
          <li class="regular-text">Children 2 years or under must wear a swim diaper. Only Little Swimmers type swim diapers are allowed.</li>
          <li class="regular-text">It is compulsory to take a shower before swimming.</li>
          <li class="regular-text">Wearing shoes or boots is prohibited.</li>
          <li class="regular-text">Aqua shoes are not permitted on the slides.</li>
          <li class="regular-text">Bags and accessories are not allowed.</li>
          <li class="regular-text">Running or shoving is prohibited.</li>
          <li class="regular-text">Climbing on the structure is prohibited.</li>
          <li class="regular-text">Alcohol and psychotropic substances are prohibited.</li>
          <li class="regular-text">Smoking or vaping is not permitted on site.</li>
          <li class="regular-text">Always respect on-duty attendants’ instructions.</li>
          <li class="regular-text">We are not responsible for lost or stolen items.</li>
          <li class="regular-text">Taking photos is prohibited.</li>
        </ul>

        <!-- <div class="best-price">
          <img src="@/assets/img/best-price-en.svg" alt="Meilleur prix garanti Tremblant">
          <p class="regular-text">You can reserve any of our lodging properties by visiting <a href="http://tremblant.ca/" target="_blank">tremblant.ca</a> or calling our central reservations office. Part of our mission is also making sure that you get the best price PERIOD. What this means is if you find a better price for your lodging anywhere on the Internet than the one you already reserved with us, we will match that price.</p>
        </div> -->
      </div>
      
    </section>

    <footer-bar></footer-bar>

    <!--Logo Partenaire-->
    <div class="logo-partenaire">
      <a v-if="$route.meta.lang === 'fr'" href="https://www.laurentides.com/fr" target="_blank"
        ><img src="@/assets/img/logo-tl-fr.png" alt="Logo Tourisme Laurentides"
      /></a>
      <a v-else-if="$route.meta.lang === 'en'" href="https://www.laurentides.com/en" target="_blank"
        ><img src="@/assets/img/logo-tl-en.png" alt="Logo Tourisme Laurentides"
      /></a>
    </div>

    <div class="overlay-form-loding" :class="{ show: formlodging }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidelodgingform">
      <div class="form-wrap">
        <h3 class="medium-title">{{ $t('site.reservez-long') }}</h3>

        <form
          action="https://reservations.tremblant.ca/Ecomm/Shop/Lodging/2624850/fr-CA/?_ga=2.247307652.1090954148.1629141557-1179124804.1583853558&_gl=1*t5gc5p*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTIyOTEzOC4xNC4wLjE2MjkyMjkxMzguMA"
          class="lodging-form"
          target="_blank"
        >
          <div class="input">
            <p class="regular-text">{{ $t('site.arrivee') }}</p>
            <date-picker
              name="arrivaldate"
              v-model="time1"
              :lang="this.datepickerLang"
              :disabledDate="disabledBeforeToday"
              :inputAttr="{name: 'arrivaldate', required: 'required'}"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.depart') }}</p>
            <date-picker
              name="departuredate"
              v-model="time2"
              :lang="this.datepickerLang"
              :disabledDate="disabledBeforeToday"
              :inputAttr="{name: 'departuredate', required: 'required'}"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.adulte') }}</p>
            <input
              name="adultcount"
              type="number"
              value="2"
              min="1"
              required
            >
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.enfant') }}</p>
            <input
              name="childCount"
              type="number"
              value="0"
              required
            >
          </div>
          <input type="submit" class="cta submit blanc-rouge" :value="$t('site.reservez-long')">
        </form>
      </div>
      <div class="opacity" @click.prevent="hidelodgingform"></div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'

import * as LottiePlayer from '@lottiefiles/lottie-player'

import GLightbox from 'glightbox'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import trans from '@/translations/index'

import NewsLetter from '@/components/NewsLetter'
import FooterBar from '@/components/FooterBar'

export default {
  components: { NewsLetter, FooterBar, DatePicker },
  head() {
    return {
      title: {
        inner: "Éclaté de haut en bas"
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: `Tremblant - Offres` },
        {
          name: 'description',
          content: `Profitez des meilleures offres et prix de l'année.`,
          id: 'desc'
        },
        // Twitter OG
        { name: 'twitter:title', content: `Tremblant - Offres` },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: `Profitez des meilleures offres et prix de l'année.`
        },
        // Facebook / Open Graph
        { property: 'og:title', content: `Tremblant - Offres` },
        { property: 'og:site_name', content: `Tremblant - Offres` },
        {
          property: 'og:description',
          content: `Profitez des meilleures offres et prix de l'année.`
        },
        // Og Image
        {
          p: 'og:image',
          c:
            ''
        },
        {
          name: 'twitter:image',
          content: ''
        }
      ]
    }
  },
  props: {
    pageData: {
      type: Object,
      required: true,
      default: null
    },
  },
  data() {
    return {
      toggle: true,
      formlodging: false,
      sidepanel: false,
      lottieInstance: null,
      lightboxelement: null,
      datepickerLang: null,
      time1: new Date(),
      time2: null
    }
  },
  computed: mapState('app', ['appTitle']),
  methods: {
    goback() {
        return this.$router.go(-1)
    },
    table1() {
        this.toggle = true
    },
    table2() {
        this.toggle = false
    },
    showlodgingform() {
        if(this.formlodging) {
          this.formlodging = false
        } else {
          this.formlodging = true
        }
    },
    hidelodgingform() {
      this.formlodging = false
    },
    showsidepanel() {
        if(this.sidepanel) {
          this.sidepanel = false
        } else {
          this.sidepanel = true
        }
    },
    hidesidepanel() {
      this.sidepanel = false
    },
    disabledBeforeToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    snowscroll() {
      const st = document.documentElement.scrollTop

      const element = this.$el.querySelector('.snow-background')
      const elementStyle = element.style

      element.style.top = `${ -150 + st /2}px`
    } 
  },
  mounted () {
    this.lottieInstance = LottiePlayer

    this.lightboxelement = GLightbox
    GLightbox()

    const dt = new Date()
    dt.setDate(dt.getDate() + 1)
    this.time2 = dt

    this.datepickerLang = trans.fr.datepicker
    if (this.$route.meta.lang === 'en') {
      this.datepickerLang = trans.en.datepicker
    }
  },
  created() {
      window.addEventListener('scroll', () => {
        this.snowscroll()
      })
    },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
